import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface saveReq {
  pics: string // 报销图片
  project_id: string // 项目id
  content: string
}
// export type saveProject = saveReq //导出请求保存的数据结构

// 进度汇报 添加接口
export function saveProgressReport(data: saveReq) {
  const url = '/api/project_progress/save'
  return cateringRequest.post(url, data)
}

export function saveFollowLog(data: saveReq) {
  const url = '/api/project_follow_log/save'
  return cateringRequest.post(url, data)
}
